import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkInProgressBanner from "../components/work-in-progress-banner"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="TDD Phoenix" />
    <div className="px-4 mx-auto sm:px-2 max-w-xl lg:max-w-4xl">
      <header className="mb-20 mt-4 md:mt-10">
        <div className="mx-auto bg-gray-800 text-gray-100 py-4 rounded-md max-w-xs shadow-lg">
          <div style={{ height: "350px" }}>
            <h1 className="antialiased align-middle text-center max-w-sm lg:px-4 font-extrabold text-3xl">
              Test-Driven Development with Phoenix
            </h1>
            <div className="flex">
              <Img
                className="mx-auto"
                fixed={data.file.childImageSharp.fixed}
                alt="TDD Phoenix Logo"
              />
            </div>
          </div>
          <div className="text-center align-bottom text-sm">German Velasco</div>
        </div>
      </header>
      <div className="border-t-2 border-gray-200 pt-10">
        <h2 className="text-center text-2xl lg:text-3xl">Table of Contents</h2>
        <div className="flex flex-col pt-8 pb-16">
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div className="py-4" key={node.id}>
              <div className="text-gray-600">
                Chapter {node.frontmatter.chapter}
              </div>
              <div className="pt-1">
                <Link
                  className="hover:underline text-2xl lg:text-3xl"
                  to={node.fields.slug}
                >
                  {node.frontmatter.title}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <WorkInProgressBanner />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___chapter] }) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            chapter
          }
        }
      }
    }
    file(relativePath: { eq: "tdd-phoenix-logo.png" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
