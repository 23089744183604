import React from "react"
import { Link } from "gatsby"

const WorkInProgressBanner = () => (
  <div
    className="my-8 mx-auto px-4 py-1 max-w-3xl lg:px-8 lg:max-w-4xl xl:max-w-5xl bg-orange-100 border-l-4 border-orange-500 text-orange-700"
    role="alert"
  >
    <p className="font-bold">This is a work in progress.</p>
    <p>
      To find out more, see{" "}
      <Link className="italic underline" to="/work-in-progress">
        TDD Phoenix - a work in progress
      </Link>
      .
    </p>
  </div>
)

export default WorkInProgressBanner
